<template>
  <div>
    <Breadcrumbs area="Admin" :data="location" />
    <div class="content">
      <AdminPanel :data="location" />
      <div class="primary">
        <div v-if="edite.active !== true" class="box table">
          <div class="header">
            <div>
              <h1 class="header__title">{{location}}</h1>
              <p>Exibe as contas da VTEX integradas à vDash.</p>
            </div>
          </div>

          <div class="painel">
            <b-table :items="getTable.data" :fields="fields" :tbody-transition-props="transProps"></b-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs.vue";
import AdminPanel from "@/components/panel/AdminPanel.vue";

import { listarVtexAccounts } from "@/api/admin";

export default {
  components: {
    Breadcrumbs,
    AdminPanel
  },
  data() {
    return {
      regras: [],
      breadcrumbs: [],
      panel: [],
      location: "",
      seeinformation: false,
      seeupgrade: false,
      id: "",
      edite: {
        active: false,
        type: "",
      },
      del: false,
      name: "",
      email: "",
      ler: true,
      editar: false,
      gerenciar: false,
      data: {
        title: {
          top: "",
          name: "Permissões da conta",
        },
      },
      transProps: {
        // Transition name
        name: 'flip-list'
      },
      fields: [
        { key: "accountname", label: "AccountName", sortable: true }, 
        { key: "vtexappkey", label: "App Key", sortable: true }, 
        { key: "company", label: "ID empresa", sortable: true }, 
      ],
    };
  },
  mounted() {
    this.fillData();
  },
  computed: {
    getTable() {
      let data = this.$store.state.admin.vtex_accounts;

      // const users = [];

      // res.map(user => {
      //   let permissoes = "Ler";

      //   if (user.manage_users === 0 && user.manage_integrations === 1) {
      //     permissoes = "Ler e editar integrações";
      //   }

      //   if (user.manage_users === 1 && user.manage_integrations === 1) {
      //     permissoes = "Ler, editar integrações e gerenciar usuários";
      //   }

      //   users.push({
      //     id: user.user_id,
      //     nome: user.name,
      //     email: user.email,
      //     permissoes,
      //     acao: "",
      //   });
      // });

      return {
        title: {
          top: "",
          name: "Detalhamento",
        },
        data,
      };
    },
    getEdit: {
      get() {
        return this.$store.state.company.usersEdited;
      },
      set(newData) {
        return newData;
      },
    },
    userId() {
      let res = this.$store.state.user.dadosCompletos;
      return res.user_id;
    },
  },
  methods: {
    listarVtexAccounts,
    fillData() {
      this.listarVtexAccounts();
      this.location = "VTEX Accounts";
    }
  },
  watch: {
    getEdit(data) {
      this.edite.active = data;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  margin-bottom: 30px;
  &__title {
    font-size: 18px;
    font-weight: 400;
  }
}
.primary {
  width: 100%;
}
.setup {
  height: 100%;
  .page-container {
    margin: 30px 0;
  }
}
.content {
  display: flex;
  align-items: flex-start;
}

.box {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  margin-top: 0 !important;
  max-width: calc(50% - 7.5px);
  width: 100%;
  position: relative;
  background-color: #fff;
  padding: 18px;
  box-shadow: 0 3px 6px #c3d0e029;
  border-radius: 20px;
  &:nth-child(2) {
    margin-right: 0;
  }
  .painel {
    position: relative;
    height: 300px;
    display: block;
    overflow: hidden;
  }
  &.table {
    margin: 0;
    margin-top: 15px;
    height: auto;
    max-width: 100%;
    width: 100%;
    &:after {
      content: "";
      display: block;
    }
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      button {
        background-color: #41a7ff;
        max-width: 330px;
        width: 100%;
        height: 50px;
        border-radius: 5px;
        border: none;
        font-size: 16px;
        font-weight: 500;
        color: #fff;
      }
    }
    .painel {
      height: auto;
      overflow: inherit;
    }
  }
  &.edite {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    .header {
      margin-bottom: 0;
    }
    .box-inputs {
      max-width: 50%;
      width: 100%;
      padding-right: 15px;
      &-checkbox {
        max-width: 50%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 15px;
        padding-top: 0;
        padding-bottom: 55px;
        .header {
          border-bottom: 1px solid #e6e6e6;
          padding-bottom: 5px;
          label {
            font-size: 16px;
            font-weight: 600;
            color: #2c2c2c;
          }
        }
      }
      .field {
        display: flex;
        justify-content: center;
        flex-direction: column;
        &:first-child {
          margin-bottom: 40px;
        }
        label {
          font-size: 16px;
          font-weight: 600;
          color: #2c2c2c;
          margin-bottom: 5px;
        }
        input {
          height: 50px;
          border: 1px solid #e6e6e6;
          & + span {
            margin-top: 10px;
          }
        }
        span {
          font-size: 14px;
          font-weight: 200;
        }
      }
    }
    .box-btn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 50px;
      button {
        background-color: #73cb53;
        max-width: 330px;
        width: 100%;
        height: 50px;
        border-radius: 5px;
        border: none;
        font-size: 16px;
        font-weight: 500;
        color: #fff;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .box-filter {
    justify-content: flex-end;
  }
  .box {
    max-width: 100%;
    margin-top: 30px;
    margin-right: 0;
    padding: 25px;
    &.table {
      margin-top: 30px;

      overflow: scroll;
      .box-inputs,
      .box-inputs-checkbox {
        max-width: 100%;
        padding: 0;
        order: 2;
      }
      .box-inputs-checkbox {
        margin-top: 15px;
        order: 1;
      }
      .box-btn {
        order: 3;
      }
    }
    &:first-child {
      margin-top: 0;
    }
  }
}
</style>
